import React, { useState, useCallback, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';

import Users from './User/pages/Users';
import Footer from './shared/components/Footer/MainFooter'
import Home from './Home/pages/Home';
import AboutUs from './AboutUs/pages/AboutUs';
import Price from './Price/pages/Price';
import Contact from './Contact/pages/Contact';
import CancelService from './CancelService/pages/CancelService';
import TermsOfService from './TermsService/pages/TermsOfService';
import RefundPolicy from './RefundPolicy/pages/RefundPolicy';
import PrivacyPolicy from './PrivacyPolicy/pages/PrivacyPolicy';
import Checkout from './Checkout/pages/Checkout';
import haveAccess from './utils/helpers/validators/routeValidation';
import Header from './shared/components/Header/MainNavigation';
import NotFound from './NotFound/page/NotFound';
import { AuthContext } from './shared/context/auth-context';
import FloatingCancelButton from './shared/components/FloatingCancelButton/FloatingCancelButton';





const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const login = useCallback(() => {
    setIsLoggedIn(true);
  }, []);

  const logout = useCallback(() => {
    setIsLoggedIn(false);
  }, []);

  let routes;

  if (isLoggedIn) {
    routes = (
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        
        <Route path="/about-us" exact>
          <AboutUs />
        </Route>
        
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>

        <Route path="/about-us" exact>
          <AboutUs />
        </Route>

        {/* To chech the components */}
        <Route path="/price" exact>
          <Price />
        </Route>

        <Route path="/contact-us" exact>
          <Contact />
        </Route>

        <Route path="/cancel-service" exact>
          <CancelService />
        </Route>

        <Route path="/terms-of-service" exact>
          <TermsOfService />
        </Route>

        <Route path="/refund-policy" exact>
          <RefundPolicy />
        </Route>

        <Route path="/privacy-policy" exact>
          <PrivacyPolicy />
        </Route>


        <Route path="/checkout" 
          render={() =>
            haveAccess() ? (
              <Checkout />
            ) : (
              <Redirect to="/" />
            )
          }
          exact>
           
        </Route>

        <Route path="**" exact>
          <NotFound/>
        </Route>

      </Switch>
    );
  }

  useEffect(() => {
    const Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      (function(){
      const s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src=process.env.REACT_APP_TAWK_PROPERTY;
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1,s0);
      })();
  }, []);
  return (
    <AuthContext.Provider
      value={{ isLoggedIn: isLoggedIn, login: login, logout: logout }}
    >
      <Router>
        <Header />
        <FloatingCancelButton /> 
        <main>
          {routes}
        </main>
        <Footer />
      </Router>
        
    </AuthContext.Provider>
  );

  // <>
  // <Header></Header>
  // <FullHeader></FullHeader>
  // </>


};

export default App;
