import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import Swal from 'sweetalert2';
import ReCAPTCHA from 'react-google-recaptcha';
import './FloatingCancelButton.css';

const ModalContent = ({ handleRecaptchaChange }) => {
  return (
    <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '.4em' }}>
      <input id="swal-orderId" className="swal2-input" placeholder="Order ID" required style={{ width: '100%' }} />
      <input id="swal-phone" className="swal2-input" placeholder="Your phone number" required style={{ width: '100%' }} />
      <input id="swal-email" className="swal2-input" placeholder="Your email" required style={{ width: '100%' }} />
      <select id="swal-reason" className="swal2-input" required style={{ width: '100%', marginTop: '.4em' }}>
        <option value="">Select the reason</option>
        <option value="Too expensive">Too expensive</option>
        <option value="Not longer needed">Not longer needed</option>
        <option value="Didn't receive welcome letter">Didn't receive welcome letter</option>
        <option value="Not what I was looking for">Not what I was looking for</option>
        <option value="I have never subscribed">I have never subscribed</option>
      </select>
      <textarea id="swal-message" className="swal2-textarea" placeholder="Additional Details..." style={{ width: '100%' }}></textarea>
      <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA} onChange={handleRecaptchaChange} />
    </div>
  );
};

const FloatingCancelButton = () => {
  const [_, setRecaptcha] = useState(false);
  const recaptchaRef = useRef(false);

  const handleRecaptchaChange = (val) => {
    recaptchaRef.current = !!val;
    setRecaptcha(!!val);
  };

  const handleOpenModal = () => {
    Swal.fire({
      title: 'Cancellation Request.',
      html: '<div id="react-swal-content"></div>',
      showCancelButton: true,
      confirmButtonText: 'Send',
      cancelButtonText: 'Cancel',
      didOpen: () => {
        ReactDOM.render(
          <ModalContent handleRecaptchaChange={handleRecaptchaChange} />,
          document.getElementById('react-swal-content')
        );
      },
      preConfirm: () => {
        const orderId = document.getElementById('swal-orderId').value.trim();
        const phoneNumber = document.getElementById('swal-phone').value.trim();
        const email = document.getElementById('swal-email').value.trim();
        const motivo = document.getElementById('swal-reason').value;
        const message = document.getElementById('swal-message').value.trim();

        const phoneRegex = /^[0-9]{10}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!orderId || !phoneNumber || !email || !motivo || !recaptchaRef.current) {
          Swal.showValidationMessage('All fields are required and ReCAPTCHA must be completed.');
          return false;
        }

        if (!phoneRegex.test(phoneNumber)) {
          Swal.showValidationMessage('Phone number must be numeric and 10 digits.');
          return false;
        }

        if (!emailRegex.test(email)) {
          Swal.showValidationMessage('Please enter a valid email address.');
          return false;
        }

        return { 
          orderId, 
          phoneNumber, 
          email, 
          motivo, 
          message, 
          recaptcha: recaptchaRef.current, 
          web_url: window.location.href 
        };
      }
    }).then((result) => {
      if (result.isConfirmed) {
        sendDataToEndpoint(result.value);
      }
    });
  };

  const sendDataToEndpoint = async (data) => {
    try {
      const response = await fetch(process.env.REACT_APP_CANCEL_AGM_PRODUCT, {
        method: 'POST',
        body: JSON.stringify(data),
      });

      if (response.ok) {
        Swal.fire('The cancellation was sent', 'One of our advisors will contact you soon.', 'success');
      } else {
        throw new Error('Server error, try again');
      }
    } catch (error) {
      Swal.fire('Error', 'The request could not be sent', 'error');
      console.error('Error:', error);
    }
  };

  return (
    <button className="floating-button" onClick={handleOpenModal}>Cancel Product</button>
  );
};

export default FloatingCancelButton;